.detail-container {
  .player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .content-header {
    background: rgb(255, 240, 158);
    color: rgb(230, 0, 18);
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px;
    margin: 20px 0;
    border-radius: 5px;
  }

  .article-content {
    padding: 12px 12px 8px;
    .article-title {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.0rem;

      @media (max-width: 767.98px) {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
    }
    .article-data {
      padding-top: 2px;
      .article-tags, .article-info {
        flex-wrap: wrap;
        display: flex;
        align-items: baseline;
      }
      ul.article-tags {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          &:not(:last-child) {
            margin-right: 10px;
            margin-bottom: 5px;
          }
          a {
            color: var(--link-color);
            font-size: 1.2rem;
            display: inline-block;
            text-decoration: none;
            &::before {
              content: '#';
            }
            @media (max-width: 767.98px) {
              font-size: 1.0rem;
            }
          }
        }
      }

      .article-info-wrap {
        display: flex;
        flex-direction: row;
        padding-top: 5px;
        .article-info {
          font-size: 1.2rem;
          font-weight: 400;
          color: var(--sub-character-color);
          @media (max-width: 767.98px) {
            font-size: 1.0rem;
          }
        }
        ul.article-info {
          list-style: none;
          padding: 0;
          margin: 0;
          .article-date::after {
            content: "･";
            padding: 0px 4px;
          }
        }
      }
    }
  }

  .rev-content {
    padding: 8px 12px 20px 12px;
    .rev-desc {
      margin: 10px 0;
      line-height: 130%;
      font-size: 1.1rem;
      letter-spacing: 0.1rem;
    }
  }

  .dmm-content {
    padding: 8px 12px 20px 12px;
    // .dmm-title {
    //   font-size: 1.6rem;
    //   font-weight: 500;
    //   line-height: 2.0rem;

    //   @media (max-width: 767.98px) {
    //     font-size: 1.2rem;
    //     line-height: 1.5rem;
    //   }
    // }
    .dmm-litevideo-wrap {
      position: relative;
      padding-bottom: 75%;
      height: 0;
      overflow: hidden;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        max-width: 1280px;
      }
    }
    // .dmm-thumb {
    //   img {
    //     width: 100%;
    //   }
    // }
    .dmm-desc {
      margin: 10px 0;
      line-height: 130%;
      font-size: 1.1rem;
      letter-spacing: 0.1rem;
    }
    // .dmm-link-wrap {
    //   margin: 20px 0;
    //   .dmm-link {
    //     display: block;
    //     width: 320px;
    //     height: 50px;
    //     line-height: 50px;
    //     margin: 0 auto;
    //     background: #ff8b12;
    //     border-radius: 25px;
    //     border-bottom: 2px solid #c26b0f;
    //     color: #ffffff;
    //     font-weight: normal;
    //     transition: all ease-out 0.1s;
    //     cursor: pointer;
    //     text-align: center;

    //     @media (max-width: 767.98px) {
    //       width: 220px;
    //     }
    //     &:hover {
    //       opacity: 0.8;
    //     }
    //     a {
    //       display: block;
    //       color: #ffffff;
    //       font-size: 1.3rem;
    //       font-weight: bold;
    //       letter-spacing: 1px;
    //       background-color: transparent;
    //       text-decoration: none;
    //       outline: none;
    //       @media (max-width: 767.98px) {
    //         font-size: 1rem;
    //       }
    //     }
    //   }
    // }
  }

  .related-items {
    padding: 12px 12px 8px;
    .related-items-title {
      position: relative;
      font-size: 1.8rem;
      font-weight: 400;
      color: var(--character-color);
    }
    .article-item-col {
      padding-bottom: 10px;
    }
  }
}

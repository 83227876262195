.article-item {
  a {
    display: block;
    text-decoration: none;
    height: 100%;
  }

  .article-item-thumb {
    position: relative;
    aspect-ratio: 16 / 9;
    overflow: hidden;

    .article-item-image  {
      width: 100%;
      height: auto;
    }
    .article-item-ad-image  {
      width: 100%;
      height: 100%;
    }
    .playtime {
      bottom: 5px;
      right: 5px;
    }
    .video-data {
      position: absolute;
      padding: 2px 5px;
      width: auto;
      height: auto;
      line-height: 1.6rem;
      color: #fff; //var(--bright-color);
      text-align: center;
      font-size: 1.1rem;
      font-weight: 300;
      background: #111;
      opacity: .8;
      border-radius: 2px;
    }
    .videoplay-icon {
      display: inline;
      color: #fff;
      position: absolute;
      top: calc(50% - 48px/2);
      left: calc(50% - 48px/2);
      visibility: hidden;
      opacity: 0;
      transition: all .15s linear;
      width: 48px;
      height: 48px;
    }
  }

  .article-item-content {
    display: flex;
    padding: 8px 10px 10px 10px;

    .article-item-title {
      color: var(--character-color);
      display: block;
      text-align: left;
      font-size: 1.1rem;
      font-weight: 400;
      width: 100%;
      height: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0;
    }

    .article-item-data {
      padding-top: 4px;
      font-size: 0.9rem;
      font-weight: 400;
      color: var(--sub-character-color);
      overflow: hidden;
      height: auto;

      .article-item-text {
        line-height: 1.8;
        overflow: hidden;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  &:hover {
    -webkit-box-shadow: 0 1px 5px 2px rgb(0 0 0 / 10%);
    box-shadow: 0 1px 5px 2px rgb(0 0 0 / 10%);

    .videoplay-icon {
      visibility: inherit;
      opacity: .8;
    }
  }
}


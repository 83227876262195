@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Roboto', sans-serif;
}

#root {
  height: 100%;
}

svg {
  fill: currentColor;
}

.app {
  --character-color: rgba(0, 0, 0, 0.8);
  --sub-character-color: rgba(0, 0, 0, 0.5);
  --link-color: #065fd4;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .app-main {
    height: 100%;
    display: flex;
  }
  main {
    padding: 24px 24px 5px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}

.sidemenu-container {
  .search-box {
    padding: 20px;
  }
}

footer {
  .inquiry-link {
    cursor: pointer;
  }
}

.search-container {
  padding-bottom: 30px;

  .article-item-col {
    padding-bottom: 15px;
  }
  .post-count {
    font-size: 1.1rem;
  }
}
